import React from "react";

const Header = () => {

    return (
        <div className={"row"}>
            <p className={"page-header"}>Trello Dashboard 🗂️</p>
        </div>
    )
}

export default Header;