import React from "react";
import Board from "./pages/Board"
import BoardVersionII from "./pages/Board2.0";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Board />} />
        <Route path="/revised" element={<BoardVersionII />} />
      </Routes>

    </Router>
  );
};

export default App;