import React, { useState, useEffect } from "react";
import Item from "../components/Item";
import DropWrapper from "../components/DropWrapper";
import Col from "../components/Col";
import { data } from "../data";
import axios from 'axios';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Header from "../components/Header"


const Board = () => {
    // const [items, setItems] = useState(data);
    const [items, setItems] = useState([]);
    const [dataFromDatabase, setDataFromDatabase] = useState([])
    const developmentUrl = "http://localhost:8000/task";
    const productionUrl = "https://eddyxwong-todo-app-backend.vercel.app/task";
    const apiUrl = process.env.NODE_ENV === "production" ? productionUrl : developmentUrl;

    useEffect(() => {
        // Define the URLs for development and production
        handleGetData()

    }, []);

    const handleGetData = () => {
        // Conditionally fetch data based on the environment

        axios.get(apiUrl)
            .then((response) => {
                // setDataFromDatabase(response.data);
                console.log(response.data)
                setItems(response.data)
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
            });
    }
    const updateItemStatusInDatabase = (itemId, name, des, newStatus) => {
        const endPoint = apiUrl + "/" + itemId
        axios.patch(endPoint, {
            task_name: name,
            task_des: des,
            status: newStatus
        })
            .then((e) => {
                console.log(e)
            })
            .catch((error) => {
                console.error("Error upadint task status", error)
            })
    }

    const moveItem = (dragIndex, hoverIndex) => {
        const item = items[dragIndex];
        setItems(prevState => {
            const newItems = prevState.filter((i, idx) => idx !== dragIndex);
            newItems.splice(hoverIndex, 0, item);
            return [...newItems];
        });
    };
    const onDrop = (item, monitor, status) => {
        const mapping = statuses.find(si => si.status === status);
        // Update the item's status in the database
        updateItemStatusInDatabase(item.id, item.task_name, item.task_des, status);

        setItems(prevState => {
            const newItems = prevState
                .filter(i => i.id !== item.id)
                .concat({ ...item, status, icon: mapping.icon });
            return [...newItems];
        });
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Header />
            {/* <button onClick={handleGetData}>Hello Bruv</button> */}
            <div className={"row"}>
                {statuses.map(s => {
                    return (
                        <div key={s.status} className={"col-wrapper"}>
                            <h2 className={"col-header"}>{s.status.toUpperCase()}</h2>
                            <DropWrapper onDrop={onDrop} status={s.status}>
                                <Col>
                                    {items
                                        .filter(i => i.status === s.status)
                                        .map((i, idx) => <Item key={i.id} item={i} index={idx} moveItem={moveItem} status={s} />)
                                    }
                                </Col>
                            </DropWrapper>
                        </div>
                    );
                })}
            </div>
        </DndProvider>
    );
};

const statuses = [{
    status: "open",
    icon: "⭕️",
    color: "#EB5A46"
}, {
    status: "in progress",
    icon: "🔆️",
    color: "#00C2E0"
}, {
    status: "in review",
    icon: "📝",
    color: "#C377E0"
}, {
    status: "done",
    icon: "✅",
    color: "#3981DE"
}];

export default Board;